.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  /* transform: translate(5%, 20%); */
  /* background-color: white; */
  z-index: 1000;
  padding: 10px;
  max-width: 90%;
  max-height: 90%;
  border-radius: 1em;
}

.modal-content {
  flex: 1; /* Allow modal content to grow and fill available space */
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.772);
  z-index: 999;
}

.modal-close-button {
  align-self: flex-center;
  padding-top: 0.2em;
  margin-top: 1em;
  width: 10em;
}

.modal-image {
  flex: 0; /* Disable image from growing; maintains its aspect ratio */
  max-height: 35vh; /* Set maximum height for the image */
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
}

@media (min-width: 768px) {
  .modal-image {
    max-height: 70vh; /* Adjust maximum height for larger screens */
  }
}
